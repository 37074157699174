import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "title": "Tofu für Grill und Pfanne",
  "preis": "2,00-4,00 € pro 100g"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`Unser Tofu ist nicht nur für Kochnerds etwas, nein, es gibt ihn auch fertig. Für die Pfanne oder für den Grill. So wie es dir am besten passt. Gemüse dazu, heiß gemacht,
Beilage nach Wahl - fertig. Gesundes Essen, lecker. `}</p>
    <p>{`Aktuell gibt es von uns die Geschmacksrichtungen:`}</p>
    <ul>
      <li parentName="ul">{`Sechuan Style in Douban- Paste mariniert`}</li>
      <li parentName="ul">{`Indian-Curry, frei zusammengesetzte Currygewürze`}</li>
      <li parentName="ul">{`Tofu nach thüringer Art. `}</li>
    </ul>
    <p>{`Unser Tofu wird vor der Marinade frittiert.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      